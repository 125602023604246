import { Pie } from 'vue-chartjs'
// arbitrary succession of colors to avoid repetition
const colorPalette = ['#89D184', '#FF8F45', '#2ACCCC', '#F8C125', '#E02020', '#FF8F45', '#F8C125', '#2ACCCC', '#89D184', '#FF8F45']

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      baseOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'right',
          labels: {
            padding: 20,
          },
        },
      },
    }
  },
  mounted() {
    this.renderPieChart()
  },
  computed: {
    computedChartData() { return this.chartdata },
  },
  methods: {
    renderPieChart() {
      this.renderChart({
        labels: this.labels,
        datasets: [
          {
            data: this.computedChartData,
            backgroundColor: this.chartdata.length > colorPalette.length
              ? colorPalette.concat(colorPalette)
              : colorPalette,
          },
        ],
      }, { ...this.baseOptions, ...this.options })
    },
  },
  watch: {
    chartdata() {
      this.$data._chart.update()
      this.renderPieChart()
    },
  },
}
